<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field 
            type="user" 
            label="안전관리자" 
            name="chmDangerManageUserId" 
            v-model="searchParam.chmDangerManageUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field 
            type="user" 
            label="LBLMANAGER" 
            name="chmDangerUserId" 
            v-model="searchParam.chmDangerUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field 
            type="user" 
            label="안전대리자" 
            name="chmDangerSafetyUserId" 
            v-model="searchParam.chmDangerSafetyUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            :comboItems="useFlagItems"
            itemText="codeName"
            itemValue="code"
            label="LBLUSEFLAG"
            name="useFlag"
            v-model="searchParam.useFlag">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="위험물 저장소 목록"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn label="LBLREG" v-if="editable" icon="add" @btnClicked="onItemClick" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "danger-master",
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "LBLPLANT",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "chmDangerTypeName",
            field: "chmDangerTypeName",
            label: "구분",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
          {
            name: "chmDangerArea",
            field: "chmDangerArea",
            label: "설치장소",
            style: 'width:200px',
            align: "left",
            type: "link",
            sortable: true,
          },
          {
            name: "chmDangerMstName",
            field: "chmDangerMstName",
            label: "품명 표기",
            align: "left",
            sortable: true,
          },
          {
            name: "chmDangerManageUserName",
            field: "chmDangerManageUserName",
            label: "안전관리자",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "chmDangerUserName",
            field: "chmDangerUserName",
            style: 'width:150px',
            label: "LBLMANAGER",
            align: "center",
            sortable: true,
          },
          {
            name: "chmDangerSafetyUserName",
            field: "chmDangerSafetyUserName",
            style: 'width:150px',
            label: "안전대리자",
            align: "center",
            sortable: true,
          },
          {
            name: "chmDangerCount",
            field: "chmDangerCount",
            label: "지정수량 표기",
            align: "left",
            sortable: true,
          },
          {
            name: "chmDangerMainUse",
            field: "chmDangerMainUse",
            style: 'width:150px',
            label: "주용도",
            align: "left",
            sortable: true,
          },
          {
            name: "useFlagName",
            field: "useFlagName",
            style: 'width:80px',
            label: "사용여부",
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        chmDangerManageUserId: '',
        chmDangerUserId: '',
        chmDangerSafetyUserId: '',
      },
      useFlagItems: [ // 사용여부
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      listUrl: "",
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.chm.danger.master.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    onItemClick() {
      this.linkClick();
    },
    linkClick(row) {
      this.popupOptions.title = "위험물저장소 상세"; // 위험물저장소 상세
      this.popupOptions.param = {
        chmDangerMstId: row ? row.chmDangerMstId : '',
      };
      this.popupOptions.target = () => import(`${"./dangerMasterDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>
